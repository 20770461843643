import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import MaterialUiButton from "../../Buttons/MaterialUiButton/MaterialUiButton";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: "flex",
    justifyContent: "space-around",
    padding: "1.5rem 0",
  },
  label: {
    paddingTop: "1.5rem",
    textAlign: "center",
  },
}));

export default function GenerateCQRContent(props) {
  const { asset = {}, inventoryItem = {}, closeModal, setGenerateCQR } = props;

  const assetId = asset.assetId;
  const assetTag = asset.tag
  const inventoryItemId = inventoryItem.assetId;
  const inventoryItemTag = inventoryItem.tag

  const [isRedirect, setIsRedirect] = useState(false);
  const classes = useStyles();
  return (
    <>
      {isRedirect ? (
        <Redirect
          to={{
            pathname: `/generateCQR/`,
            state: {
              selectedAssets: {
                [assetId ? assetId : inventoryItemId ]: assetId ? asset : inventoryItem,
              },
            },
          }}
        />
      ) : null}
      <DialogContent className={classes.label}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Would you like to generate a CQR for {assetTag ? assetTag : inventoryItemTag}?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.inputContainer}>
        <MaterialUiButton
          color="cancel"
          label="No"
          onClick={() => {
            closeModal();
            setTimeout(() => {
              setGenerateCQR(false);
            }, 500);
          }}
          variant="contained"
        />
        <MaterialUiButton
          autoFocus
          color="primary"
          label="Yes"
          onClick={() => {
            setIsRedirect(true);
            setTimeout(()=>{
              closeModal();
            }, 200)
          }}
          variant="contained"
        />
      </DialogActions>
    </>
  );
}
