import { useState } from "react";
import GenerateCQRContent from "../GenerateCQRContent";
import InventoryContent from "./InventoryContent";
import ModalDialog from "../../../Modals/ModalDialog/ModalDialog";


export default function AddInventoryModal(props) {

  const { handleAlertModal, handleClose, modalOpen, onSubmit } = props;
  const [generateCQR, setGenerateCQR] = useState(false);
  const [newInventoryItem, setNewInventoryItem] = useState({});

  function closeModal() {
    handleClose();
    setTimeout(() => {
      setGenerateCQR(false);
    }, 300);
  }

  return (
    <ModalDialog
        content={
            generateCQR ? (
                <GenerateCQRContent
                    closeModal={closeModal}
                    inventoryItem={newInventoryItem}
                    setGenerateCQR={setGenerateCQR}
                />
            ) : (
                <InventoryContent
                    {...props}
                    closeModal={closeModal}
                    handleAlertModal={handleAlertModal}
                    onSubmit={onSubmit}
                    parentProps={props}
                    setGenerateCQR={setGenerateCQR}
                    setNewInventoryItem={setNewInventoryItem}
                />
            )
        }
        handleClose={closeModal}
        maxWidth="md"
        open={modalOpen}
        title={generateCQR ? "Generate CQR" : "Add Inventory"}
    />
  )
}
