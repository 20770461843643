import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { onSubmit } from "./AddInventoryModal/utils";
import AddInventoryModal from "./AddInventoryModal/AddInventoryModal";
import MaterialUiButton from "../../Buttons/MaterialUiButton/MaterialUiButton"
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0 2rem",
    minWidth: "7rem",
  },
}));

export default function AddInventoryButton(props) {
    
  const classes = useStyles();
//   initial state responsible for modal triggering amongst other things
  const [state, setState] = useState({
    alertModalContent: "",
    alertModalOpen: false,
    alertModalSeverity: "success",
    modalOpen: false,
  });
  const handleAlertModal = (content) => {
    const {
      alertModalContent,
      alertModalSeverity = "success",
      alertModalTitle,
    } = content;

    setState({
      ...state,
      alertModalContent,
      alertModalOpen: true,
      alertModalSeverity,
      alertModalTitle,
    });
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={state.alertModalOpen}
        onClose={state.closeModal}
      >
        <MuiAlert
          onClose={state.closeModal}
          severity={state.alertModalSeverity}
        >
          {state.alertModalContent}
        </MuiAlert>
      </Snackbar>
      <AddInventoryModal
        {...props}
        handleAlertModal={handleAlertModal}
        handleClose={() => {
          setState((prevState) => ({ ...prevState, modalOpen: false }));
          setTimeout(() => {
            setState((prevState) => ({ ...prevState, alertModalOpen: false }));
          }, 3000);
        }}
        modalOpen={state.modalOpen}
        onSubmit={onSubmit}
      />
      <MaterialUiButton
        className={classes.button}
        color="primary"
        label="ADD INVENTORY"
        onClick={() => {
          setState((prevState) => ({ ...prevState, modalOpen: true }));
        }}
        size="small"
        variant="contained"
      />
    </div>
  );
}
