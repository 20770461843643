import escapeCharacters from "../../utils/escapedCharacters";
import moment from "moment-timezone";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const searchHistories = async (props, state) => {
  const { classifications, organizationId, token } = props;
  const {
    assetId,
    filters = {},
    isExport,
    isInventorySnapshot = false,
    page = 0,
  } = state;
  let {
    allDevicesMap = {},
    assetCategories,
    assetIds,
    assetType,
    binLocation,
    endDate,
    events,
    hideScans = true,
    limit,
    locals,
    locations,
    pca,
    proximity = false,
    sorted,
    startDate,
    users,
    zones,
  } = filters;
  let parsedSorted =
    sorted && sorted.length
      ? (() => {
          let obj = {
            id: "",
            order: sorted[0].desc ? "DESC" : "ASC",
          };
          switch (sorted[0].id) {
            case "timeOfLog":
              obj.id = "time_of_log";
              break;
            // case "assetType":
            //   obj.id = "asset_type";
            //   break;
            case "category":
              obj.id = "category";
              break;
            case "assetTag":
              obj.id = "asset_tag";
              break;
            case "event":
              obj.id = "event";
              break;
            case "state":
              obj.id = "state";
              break;
            case "facility":
              obj.id = "facility";
              break;
            case "latLong":
              if (sorted[0].desc) {
                obj.id = "latitude DESC, longitude ASC";
              } else {
                obj.id = "latitude ASC, longitude DESC";
              }
              obj.latLong = true;
              break;
            case "user":
              obj.id = "last_name";
              break;
            case "binLocation":
              obj.id = "bin_location";
              break;
            default:
              obj.id = "time_of_log";
              break;
          }
          return obj;
        })()
      : "";
  let sortedString =
    parsedSorted && parsedSorted.id
      ? (() => {
          if (parsedSorted.latLong) {
            return `${parsedSorted.id}`;
          } else {
            return `${parsedSorted.id} ${parsedSorted.order}`;
          }
        })()
      : "";

  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must_not: [
          {
            terms: {
              // If the user is on the Inventory Snapshot page, then we do not need
              // to exclude this
              asset_mode: [
                isInventorySnapshot ? "" : "Inventory",
                "Device",
                "Product",
              ],
            },
          },
        ],
        must: [
          {
            term: {
              organization_id: organizationId,
            },
          },
          {
            bool: {
              must_not: [
                {
                  nested: {
                    path: "device",
                    query: {
                      exists: {
                        field: "device.status",
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },
    limit: isExport ? 25000 : limit || 25,
    page,
    sort: `${sortedString}`,
  };

  // escape special characters from string inputs
  let escapedAssetType = assetType ? escapeCharacters(assetType) : null;

  // Asset Id - In the case we are looking for histories from a certain assetId
  if (assetId) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: {
        asset_id: assetId,
      },
    });
  }

  // Asset Tag
  if (assetIds && assetIds.length) {
    let assetIdArray = assetIds.map((assetId) => {
      return assetId.value;
    });
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: {
        asset_id: assetIdArray,
      },
    });
  }

  // Asset Type
  if (escapedAssetType) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: {
        asset_type: escapedAssetType,
      },
    });
  }

  // Bin Location
  if (binLocation?.length) {
    const binLocationsArray = binLocation?.map((bin) => {
      return {
        term: {
          "zone.bin_location.keyword": bin.value,
        },
      };
    });

    elasticQuery.elasticSearchQuery.bool.must.push({
      bool: {
        should: [
          {
            nested: {
              path: "zone",
              query: {
                bool: {
                  should: binLocationsArray,
                },
              },
            },
          },
        ],
      },
    });
  }

  // Categories
  if (assetCategories?.length > 0) {
    const assetCategoriesArray = assetCategories.map((cat) => {
      return cat.value;
    });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { category: assetCategoriesArray },
    });
  }

  // Classifications
  const classificationFilters = Object.keys(filters || {})
    .filter(
      (filterKey) => classifications[filterKey] && filters[filterKey] !== null
    )
    .map((filterKey) => filters[filterKey]);

  if (classificationFilters?.length) {
    classificationFilters?.map((classArray) => {
      // We initially declare the object we are going to push into the query
      let queryObject = {
        nested: {
          path: "classification_data",
          query: {
            bool: {
              must: [],
              should: [],
              minimum_should_match: 1,
            },
          },
        },
      };

      // We build up the queryObject here
      classArray?.map((childClass) => {
        // Make sure the parentId is present
        if (queryObject.nested.query.bool.must.length === 0) {
          queryObject.nested.query.bool.must.push({
            match: {
              "classification_data.key.keyword": childClass.parentLabel,
            },
          });
        }

        // Push the children
        queryObject.nested.query.bool.should.push({
          match: {
            "classification_data.value.keyword": childClass.label,
          },
        });
        return null;
      });

      elasticQuery.elasticSearchQuery.bool.must.push(queryObject);

      return null;
    });
  }

  // Device Filter - build filter query string from all the asset ids found in the allDevicesMap to filter out asset histories that may have come from devices
  const filterDevicesString = Object.keys(allDevicesMap).map((deviceId) => {
    return deviceId;
  });

  elasticQuery.elasticSearchQuery.bool.must_not.push({
    terms: {
      asset_id: filterDevicesString,
    },
  });

  // Events
  if (events && events.length) {
    // We Build up the array
    let eventArray = events.map((e) => e.value);

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { event: eventArray },
    });
  }

  // Facility
  if (locations && locations.length) {
    // We build the array
    let locationsSet = locations.map((e) => {
      return e.value?.facilityId || e;
    });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { facility_id: locationsSet },
    });
  }

  // PCA
  if (pca) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { flagged: true },
    });
  }

  // Proximity
  if (proximity) {
    elasticQuery.elasticSearchQuery.bool.must_not.push({
      term: {
        event_type: "device_proximity",
      },
    });
  }

  // Scans
  if (hideScans) {
    elasticQuery.elasticSearchQuery.bool.must_not.push({
      term: {
        event: "Scan – LogisticsPro",
      },
    });
  }

  // State
  if (locals && locals.length) {
    // We build the array
    let localsArray = locals.map((e) => {
      return e.value;
    });
    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { state: localsArray },
    });
  }

  // Users
  if (users?.length) {
    // We Build the Array
    const usersArray = users.map((user) => {
      return user.value;
    });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { app_user_id: usersArray },
    });
  }

  // Zones
  if (zones && zones.length) {
    let zonesArray = zones.map((zone) => {
      const { internalZoneType = null, value = "" } = zone;

      // if {zoneId}.internalZoneId === 'processing' then we know the zone is a
      // pZone. If that value is === 'target' then we know the zone is a tZone.

      if (internalZoneType === "processing") {
        return { "zone.p_zone_id": value };
      } else if (internalZoneType === "target") {
        return { "zone.t_zone_id": value };
      } else {
        return { "zone.zone_id": value };
      }
    });
    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      nested: {
        path: "zone",
        query: {
          bool: {
            must: [
              {
                terms: {
                  "zone.zone_id": zonesArray,
                },
              },
            ],
          },
        },
      },
    });
  }

  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  if (startDate || endDate) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      range: {
        time_of_log: {
          gte: moment(startDate).isValid()
            ? moment(startDate).startOf("day").valueOf()
            : null,
          lte: moment(endDate).isValid()
            ? moment(endDate).endOf("day").valueOf()
            : "now",
        },
      },
    });
  }

  const results = await fetch(`${apiUrl}assetHistories/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error:
          "Failed to fetch asset history data, please contact system administrator.",
      };
    });

  return results;
};

export const saveFilterSettings = async (props, filters) => {
  const { token, appUserId } = props;
  const payload = {
    propertiesMap: {
      assetHistoryTableSettings: filters,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${appUserId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const assetHistory = {
  searchHistories,
  saveFilterSettings,
};
