import ActionButtons from "../../../../components/Buttons/ActionButtons";
import MaterialUiDataGridPro from "../../../../components/Tables/DataGridPro/MaterialUiDataGridPro";
import {
  GridToolbar,
} from "@mui/x-data-grid-pro";

export default function FacilityTable(props) {
  const {
    dialog,
    permissions,
    setDialog,
    setRedirect,
    setView,
    state = {},
  } = props;
  const { facilities } = state
  const { editLocation, deleteLocation } = permissions;

  const tableColumns = [
    {
      field: "name",
      flex: 2,
      headerName: "Facility Name",
      id: "name",
      renderCell: (data) => {
        const row = data.row;
        return (
          <div
            className="pointer"
            onClick={() => {
              setDialog({
                ...dialog,
                dialogShow: true,
                selectedLocation: row,
                dialogTitle: "Facility Detail",
              });
            }}
          >
            {row.name}
          </div>
        );
      },
    },
    {
      field: "facilityType",
      flex: 1,
      headerName: "Facility Type",
      id: "facilityType",
      renderCell: (data) => {
        const row = data.row;
        return (
          <div
            className="pointer"
            onClick={() => {
              setDialog({
                ...dialog,
                dialogShow: true,
                selectedLocation: row,
                dialogTitle: "Facility Detail",
              });
            }}
          >
            {row.facilityType}
          </div>
        );
      },
    },
    {
      field: "location?.city",
      flex: 1,
      headerName: "City",
      id: "location?.city",
      renderCell: (data) => {
        const row = data.row;
        const { location = {} } = row;
        const { city = "" } = location;
        return (
          <div
            className="pointer"
            onClick={() => {
              setDialog({
                ...dialog,
                dialogShow: true,
                selectedLocation: row,
                dialogTitle: "Facility Detail",
              });
            }}
          >
            {city}
          </div>
        );
      },
    },
    {
      field: "location?.state",
      flex: 1,
      headerName: "State",
      id: "state",
      renderCell: (data) => {
        const row = data.row;
        const { location = {} } = row;
        const { state = "" } = location;
        return (
          <div
            className="pointer"
            onClick={() => {
              setDialog({
                ...dialog,
                dialogShow: true,
                selectedLocation: row,
                dialogTitle: "Facility Detail",
              });
            }}
          >
            {state}
          </div>
        );
      },
    },
    {
      disableColumnMenu: true,
      disableSortBy: true,
      flex: 2,
      headerName: "Actions",
      id: "facilityId",
      disableSortBy: true,
      renderCell: (data) => {
        const row = data.row;
        const buttonIcons = [];
        [
          "Zones",
          "Facility Layout",
          "Facility Area",
          "Edit Facilities",
          "Delete Facilities",
        ].forEach((element) => {
          switch (element) {
            case "Zones":
              if (editLocation) {
                buttonIcons.push({
                  icon: "fas fa-object-group",
                  title: "Facility Zones",
                  action: () => {
                    setView({
                      facility: row,
                      viewZones: true,
                    });
                  },
                });
              }
              break;
            case "Facility Layout":
              buttonIcons.push({
                icon: "fas fa-building",
                title: "Facility Layout",
                action: () => {
                  setRedirect({
                    isRedirect: true,
                    selectedFacility: row,
                  });
                },
              });
              break;
            case "Facility Area":
              if (editLocation) {
                buttonIcons.push({
                  icon: "fas fa-vector-square",
                  title: "Facility Area",
                  action: () => {
                    setDialog({
                      ...dialog,
                      dialogShow: true,
                      selectedLocation: row,
                      dialogTitle: "Facility Area",
                    });
                  },
                });
              }
              break;
            case "Edit Facilities":
              if (editLocation) {
                buttonIcons.push({
                  icon: "fas fa-edit",
                  title: "Edit",
                  action: () => {
                    setDialog({
                      ...dialog,
                      dialogShow: true,
                      selectedLocation: row,
                      dialogTitle: "Edit Facility",
                    });
                  },
                });
              }
              break;
            case "Delete Facilities":
              if (deleteLocation) {
                buttonIcons.push({
                  icon: "fas fa-trash-alt",
                  title: "Delete",
                  action: () => {
                    setDialog({
                      ...dialog,
                      dialogShow: true,
                      selectedLocation: row,
                      dialogTitle: "Delete Facility",
                    });
                  },
                });
              }
              break;
            default:
              break;
          }
        });
        return <ActionButtons content={buttonIcons} />;
      },
      sortable: false,
    },
  ];

  return (
    <MaterialUiDataGridPro
    checkboxSelection={false}
    columns={tableColumns}
    components={GridToolbar}
    getRowId={(row) => row.id || row.appUserId}
    loading={false}
    rowCount={facilities.length}
    rows={facilities} 
    showGridToolbar={true}
    // onPageChange={}
    // onPageSizeChange={}
    // pageSize={} //since no filters in place
  />
  );
}
