import { useEffect, useState } from "react";
import {
  DataGridPro,
  GridEventListener,
  useGridApiRef,
  GridToolbar,
} from "@mui/x-data-grid-pro";
// import CustomFooter from "./CustomFooter";
export default function MaterialUiDataGridPro(props) {
  const {
    checkboxSelection = true,
    columns = [],
    disableSelectionOnClick = true,
    getRowId = () => {},
    loading = false,
    onPageChange = () => {},
    onPageSizeChange = () => {},
    pageSize = null,
    pagination = true,
    rowHeight = 38,
    rows = [],
    rowCount = 0,
    showGridToolBar = false,
  } = props;
  const [rowCountState, setRowCountState] = useState(rowCount);
  const apiRef = useGridApiRef();
  // Taken from https://mui.com/x/react-data-grid/pagination/#basic-implementation
  useEffect(() => {
    setRowCountState((prevRowCount) =>
      rowCount !== undefined ? rowCount : prevRowCount
    );
  }, [rowCount]);
  return (
    <>
      <DataGridPro
        apiRef={apiRef}
        autoHeight={true}
        rows={rows}
        columns={columns}
        components={{
          Toolbar: showGridToolBar ? GridToolbar : null,
        }}
        // onColumnOrderChange={(change) => {
        //   recentColumnOrderChange = adjustColumnOrder(
        //     defaultColumnOrder,
        //     change
        //   );
        // }}
        // components={{
        //   Footer: CustomFooter
        // }}
        // componentsProps={{
        //   footer: { total }
        // }}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick={disableSelectionOnClick}
        experimentalFeatures={{ newEditingApi: true }}
        getRowId={getRowId}
        keepNonExistentRowsSelected
        loading={loading}
        // onFilterModelChange={(newFilterModel) => {
        //   console.log(newFilterModel)
        // }}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={pageSize}
        pagination={pagination}
        paginationMode="server"
        rowCount={rowCountState}
        rowHeight={rowHeight}
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
      />
    </>
  );
}